import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    confirmUrl: String
  };

  connect() {
    const button = this.element.querySelector('button')
    const modal = new mdb.Modal(this.element.querySelector('.modal'));
    button.addEventListener('click', () => {
      modal.show();
    });

    const confirmButton = this.element.querySelector('.btn-confirm');
    confirmButton.addEventListener('click', () => {
      console.log(this.confirmUrlValue);
      // ajax call to confirmUrlValue to cancel the appointment
      fetch(this.confirmUrlValue, {
        method: 'GET',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          'Content-Type': 'application/json'
        },
      }).then(response => {
        if (response.ok) {
          return response;
        } else {
          throw new Error('Something went wrong');
        }
      }).then(data => {
        console.log(data);
        modal.hide();
        window.location.reload();
      }).catch(error => {
        console.error(error);
      });
    });
  }

}
