import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    elapsed: Number,
    active: Boolean
  };

  connect() {
    this.displayElapsedTime();
    if (this.activeValue) {
      setInterval(() => {
        this.displayElapsedTime()
      }, 60000);
    }
  }

  displayElapsedTime() {
    if (this.activeValue) {
      this.element.innerHTML = this.numberWithCommas(this.elapsedValue.toFixed());
      this.elapsedValue++;
    }
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}
