// app/javascript/controllers/flatpickr_controller.js
import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  static values = {
    options: Object
  }

  connect() {
    this.flatpickr = flatpickr(this.element, this.optionsValue)
  }

  disconnect() {
    this.flatpickr.destroy()
  }
}
