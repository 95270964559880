// import "core-js/stable";
// import "regenerator-runtime/runtime";
// import '../stylesheets/toro.scss';
import "../stylesheets/tw-application.css";

import Alpine from 'alpinejs'

import accordion from '../../components/tui/accordion_component'
Alpine.data('accordion', accordion)
import navbar from '../../components/navbar_component'
Alpine.data('navbar', navbar)

window.Alpine = Alpine
Alpine.start()

import "@hotwired/turbo-rails"
import "../controllers"

import { start } from "@rails/activestorage";
start();

import "../channels";

const images = import.meta.glob('../images/**/*', { eager: true })
