import { Controller } from "@hotwired/stimulus";
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Webcam from '@uppy/webcam';
import ActiveStorageUpload from '@excid3/uppy-activestorage-upload';

// import "@uppy/core/dist/style.css";
// import "@uppy/dashboard/dist/style.css";

export default class extends Controller {
  static values = {
    url: String,
    name: String
  };

  static targets = ["form"];

  connect() {
    this.initializeUppy();
  }

  disconnect() {
    this.uppy.destroy();
  }

  initializeUppy() {
    this.uppy = new Uppy({
      autoProceed: true,
      allowMultipleUploads: false,
      debug: false,
      hideUploadButton: true
    }).use(Dashboard, {
      target: this.element,
      proudlyDisplayPoweredByUppy: false,
      inline: true,
      width: 300,
      height: 300
    }).use(Webcam, {
      onBeforeSnapshot: () => Promise.resolve(),
      countdown: false,
      modes: [
        'picture'
      ],
      mirror: true,
      locale: {},
      target: Dashboard,
      showVideoSourceDropdown: true
    }).use(ActiveStorageUpload, {
      directUploadUrl: this.urlValue
    });

    this.uppy.on('complete', (result) => {
      result.successful.forEach(file => {
        const hiddenField = document.createElement('input');

        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', 'file-upload-id');
        hiddenField.setAttribute('value', file.response.signed_id);

        this.formTarget.appendChild(hiddenField);
        this.formTarget.requestSubmit();
      });
    });
  }
}
