import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    containerId: String,
    maxLength: Number
  }

  count() {
    document.getElementById(this.containerIdValue).innerHTML = this.maxLengthValue - this.element.value.length;
  }
}
