export default () => ({
  mobileMenuOpen: false,

  init() {
  },

  toggleMobileMenu() {
    this.mobileMenuOpen = !this.mobileMenuOpen;
    this.updateMobileMenuIcon()
  },
  
  updateMobileMenuIcon() {
    if (this.mobileMenuOpen) {
      this.$refs.mobileMenuOpenIcon.classList.remove('block');
      this.$refs.mobileMenuOpenIcon.classList.add('hidden');
      this.$refs.mobileMenuCloseIcon.classList.remove('hidden');
      this.$refs.mobileMenuCloseIcon.classList.add('block');
    } else {
      this.$refs.mobileMenuOpenIcon.classList.remove('hidden');
      this.$refs.mobileMenuOpenIcon.classList.add('block');
      this.$refs.mobileMenuCloseIcon.classList.remove('block');
      this.$refs.mobileMenuCloseIcon.classList.add('hidden');

    }
  }

});
