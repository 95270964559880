export default () => ({
  selected: null,

  init() {
    console.log("connected...")
  },

  select(idx) {
    this.selected !== idx ? this.selected = idx : this.selected = null;
  },

  isSelected(idx) {
    return this.selected === idx
  },

  onTurboFrameLoad(idx) {
    const container = this.$refs['container' + idx];
    container.style = this.style(idx);
  },

  style(idx) {
    const container = this.$refs['container' + idx];
    return this.selected === idx ? 'max-height: ' + container.scrollHeight + 'px' : '';
  },
});
