import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    showDisplay: String,
    hideDisplay: String,
    elementId: String
  };

  toggle(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    const el = document.getElementById(this.elementIdValue);
    if(el.classList.contains('d-none')) {
      this.show();
    } else {
      this.hide();
    }
  }

  hide() {
    const el = document.getElementById(this.elementIdValue);
    el.classList.add('d-none');
    this.element.innerHTML = this.hideDisplayValue;
  }

  show() {
    const el = document.getElementById(this.elementIdValue);
    el.classList.remove('d-none');
    this.element.innerHTML = this.showDisplayValue;
  }
}
