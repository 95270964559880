
import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

export default class extends Controller {
  static values = {
    title: String,
    chartData: Array,
    lineColor: String
  }

  connect() {
    var ctx = this.element.getContext('2d');

    this.myChart = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [{
          label: this.titleValue,
          data: this.chartDataValue,
          borderColor: this.lineColorValue,
        }]
      },
      options: {
        plugins: {
            legend: {
                display: false,
            },
            title: {
              display: true,
              text: this.titleValue,
              font: {weight: 'bold', }	
          }
        }
    }
    });
  }

  disconnect() {
    this.myChart.destroy();
  }
}
