import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        expandFirst: Boolean
    };

    connect() {
        if (this.expandFirstValue) {
            // new mdb.Collapse(this.element.querySelector(".collapse"));
        }
        document.addEventListener("turbo:frame-load", () => this.setupAccordionListeners())
    }

    setupAccordionListeners() {
        this.element.querySelectorAll('.accordion-button').forEach((collapse) => {
            collapse.addEventListener('click', (e) => {
                setTimeout(() => {
                    document.querySelectorAll('.form-outline').forEach((formOutline) => {
                        new mdb.Input(formOutline).update();
                    });
                }, 300);
            });
        });
    }

}
