import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";
export default class extends Controller {
  static values = {
    modalTarget: String,
    formValidateTarget: String
  }

  validate(event) {
    event.preventDefault();

    let forms = [].slice.call(document.getElementsByClassName(this.formValidateTargetValue));

    if (forms.every(function (form) { return form.checkValidity() })) {
      new Modal(document.getElementById(this.modalTargetValue)).show();
    } else {
      let focused = false;
      for (let i = 0; i < forms.length; i++) {
        if (!forms[i].checkValidity()) {
          forms[i].classList.add('was-validated');

          if (focused === false) {
            forms[i].querySelector('.form-control').focus();
            focused = true;
          }
        }
      }
    }
  }
}
