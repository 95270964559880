import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { elapsed: Number };

  connect() {
    this.displayElapsedTime();
    setInterval(function(){ this.displayElapsedTime() }.bind(this), 60000);
  }

  displayElapsedTime() {
    this.element.innerHTML = this.numberWithCommas(this.elapsedValue.toFixed());
    this.elapsedValue++;
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}
